import * as React from "react";
import FeaturedProduct from "../components/FeaturedProduct";
import Layout from "../components/Layout";
import Section from "../components/Layout/Section";
import OurRange from "../components/OurRange";
import Projects from "../components/Projects";
import Subscribe from "../components/Subscribe";
import TextAndImage from "../components/TextAndImage";
import TextBanner from "../components/TextBanner";
import ThreeImages from "../components/ThreeImages";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Section noSpacingBottom noSpacingTop>
        <TextBanner />
      </Section>
      <Section smallSpacingTop>
        <OurRange />
      </Section>
      <Section className="bg-gray">
        <FeaturedProduct />
      </Section>
      <Section>
        <ThreeImages />
      </Section>
      <Section className="bg-gray" smallSpacingBottom>
        <Projects />
      </Section>
      <Section>
        <TextAndImage />
      </Section>
      <Section noSpacingBottom noSpacingTop>
        <Subscribe />
      </Section>
    </Layout>
  );
};

export default IndexPage;
